import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import Image from "@atoms/Image";
import { ourInvestors } from "@helpers/homepageData";
import { LazyImage } from "@ui/atoms";

const Investors = () => {
    const getAosData = (index) => {
        return {
            "data-aos": index % 2 == 0 ? 'fade-right' : 'fade-left',
            "data-aos-duration": "600",
        };
    };
    return (
        <div className="relative max-width-wrapper py-12 px-4 md:px-0 md:py-16 xl:py-18 flex flex-col items-center">
            <Text content={texts?.BackedByTopInvestors} className="md:mt-5 xl:mt-0 text-primary-500 h4-semibold md:h3-semibold xl:h2-semibold text-center" htmlTag="h2" />
            <div className="flex justify-center">
                <div className="flex justify-center items-center gap-6 md:gap-12 bg-basicWhite px-6 py-4 md:pl-[45px] md:pr-12 md:py-4 rounded-xl mt-6 md:mt-8 xl:mt-10">
                    <div className="w-[74px] h-[23px] sm:w-[117px] sm:h-[36px] md:w-[124px] md:h-[38px] lg:w-[154px] lg:h-[48px]">
                        <LazyImage
                            src="/images/homepage/investors/accel.svg"
                            alt="Accel"
                            className="relative w-full h-full opacity-60"
                        />
                    </div>
                    <div className="w-[1px] h-[30.5px] sm:h-10 md:h-[52px] lg:h-16 bg-gray-200" />
                    <div className="w-[159px] h-[42px] sm:w-[259px] sm:h-[64px] md:w-[268px] md:h-[64px] lg:w-[333px] lg:h-[90px] pt-1 lg:pt-2.5">
                        <LazyImage
                            src="/images/homepage/investors/HarmonyPartners.webp"
                            alt="Harmony Partners"
                            className="relative w-full h-full"
                        />
                    </div>
                </div>
            </div>
            <Text content={texts?.TrustedByInvestors} className="mt-6 xl:mt-8 p4-regular md:p2-regular text-gray-900 text-center" />
            <div className="mt-6 xl:mt-8 items-center justify-center flex-wrap gap-6 xl:gap-x-4 hidden md:flex">
                {
                    ourInvestors?.map((item, index) => (
                        <div key={index} className="bg-basicWhite p-4 rounded-xl flex flex-col items-center">
                            <Image src={item.image} alt={item.alt} className="w-[132px] h-[132px] rounded-lg" />
                            <Text content={item.name} className="text-basicBlack p2-semibold mt-3" />
                            <Text content={item.position} className="text-gray-600 p4-medium mt-1" />
                        </div>
                    ))
                }
            </div>
            <div className="mt-6 md:hidden flex flex-col self-stretch gap-y-4 relative">
                {
                    ourInvestors?.map((item, index) => (
                        <div key={index} className={`${index % 2 == 0 ? 'self-start' : 'self-end'} relative bg-basicWhite p-4 rounded-2xl w-[280px] flex items-center gap-x-4`} {...getAosData(index)}>
                            <Image src={item.image} alt={item.alt} className="w-16 h-16 rounded-full" />
                            <div>
                                <Text content={item.name} className="text-basicBlack p3-semibold" />
                                <Text content={item.position} className="text-gray-500 p4-regular mt-1" />
                            </div>
                        </div>
                    ))
                }
            </div>
            <Image src='/images/homepage/investors/purple-skeleton.svg' className="absolute top-4 md:top-36 xl:top-12 -right-6 h-[74px] md:h-[111px] xl:h-[148px]" />
        </div>
    );
};

export default Investors;